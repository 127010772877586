//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data () {
        return {
            oc: null,//oc图实例
            dataBase: [],//oc图元数据

            selNode: {//点击选中的node
                value: '',
                node: ''
            },
            newNodeName: "",

            users: [],//autocomplete数据
            form: {//编辑部门栏数据
                fzruserid: '',
                fzrusername: '',
                fguserid: '',
                fgusername: ''
            },

            loadingController: false,
            editShowController: false
        }
    },
    computed: {
        dataTree: function(){
            return arrBuildTree(this.dataBase, 'parentcode', 'deptcode')[0]
        },
        autocompleteTitle: function(){
            return {
                'text-overflow': 'ellipsis',
                'overflow': 'hidden'
            }
        },
        autocompletePhone: function(){
            return {
                'font-size': '12px',
                'color': '#b4b4b4'
            }
        },
        listpath:{
            get:function(){
                if(this.reqtype&&this.reqtype=="register"){
                return  "/dwdept/templist";
                }else{
                    return "/dwdept/list";
                }
            }
        },
        addpath:{
            get:function(){
                if(this.reqtype&&this.reqtype=="register"){
                return  "/dwdept/tempadd";
                }else{
                    return "/dwdept/add";
                }
            }
        }
    },
    watch: {
        dataBase: function(n, o){
            n != o && this.init()
        }
    },
    methods: {
        //部门分配
        querySearch: function(queryString, cb) {
            var users = this.users;
            var results = queryString ? users.filter(this.createFilter(queryString)) : users;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter: function(queryString) {
            return function(username) {
                return (username.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect: function(item, type) {
            if (IsNullOrEmpty(this.selNode.node)) {
                ShowMsgBox.call(this, '请选中要编辑的节点');
                this.form={
                    fzruserid: '',
                    fguserid: ''
                };
                return;
            }

            if(!type){
                this.form.fzruserid = item.userid || '';
                this.form.fzrusername = item.truename || '';
            }else{
                this.form.fguserid = item.userid || '';
                this.form.fgusername = item.truename || '';
            }
        },
        formSubmit: function(){
            if (IsNullOrEmpty(this.selNode.node)) {
                ShowMsgBox.call(this, '请选择要编辑的部门节点');
                return;
            }

            var that = this;
            var $node = this.selNode.node;

            var oldNode = JSON.parse($node.data('data'));

            var keyArr = Object.keys(this.form);
            keyArr.forEach(function(item){
                oldNode[item] = that.form[item];
            })

            this.$post(this.$store.getters.sysUrl+this.addpath, oldNode, function(data){
                ShowMsg.call(this, '部门编辑成功', 'success');
                that.queryData();
            })
        },
        formClear: function(){
            var that = this;
            var keyArr = Object.keys(this.form);
            keyArr.forEach(function(item){
                that.form[item] = '';
            })
        },

        onSubmit: function(callback){
            this.$get(this.$store.getters.sysUrl+'/dwdept/savenodes', this.dataBase, function(){
                ShowMsg.call(this, '保存完成', 'success');
                !!callback && callback();
            })
        },
        addChild: function () {
            var that = this;

            var $node = this.selNode.node
            if (IsNullOrEmpty(this.selNode.node)) {
                ShowMsgBox.call(this, '请选中父节点');
                return;
            } else if (IsNullOrEmpty(this.newNodeName)) {
                ShowMsgBox.call(this, '请填写新部门名称');
                return;
            } else {
                // console.log(JSON.parse($node.data('data')).deptcode)
                // this.oc.addChildren(that.selNode.node, { 'deptname': that.newNodeName})
                var newNodeName = {deptname: that.newNodeName}

                $.ajaxSettings.async = false;
                that.loadingController = true;
                this.$post(this.$store.getters.sysUrl+this.addpath, {
                    deptname: that.newNodeName,
                    parentcode: JSON.parse($node.data('data')).deptcode
                }, function(data){
                    ShowMsg.call(this, '子节点添加成功', 'success');
                    that.queryData();
                    that.newNodeName = '';
                    that.selNode={value:null, node:null};

                    that.editShowController = false;
                });
                that.loadingController = false;
                $.ajaxSettings.async = true;
            }
        },
        editNode: function () {
            var that = this;
            var $node = this.selNode.node;
            if (IsNullOrEmpty(that.selNode.node)) {
                ShowMsgBox.call(this, '请选中节点');
                return;
            }

            var oldNode = JSON.parse($node.data('data'));
            oldNode.deptname = that.selNode.value;

            $.ajaxSettings.async = false;
            that.loadingController = true;
            this.$post(this.$store.getters.sysUrl+this.addpath, oldNode, function(data){
                ShowMsg.call(this, '节点名称编辑成功', 'success');
                that.queryData();
            });
            that.loadingController = false;
            $.ajaxSettings.async = true;
        },
        delNode: function () {
            var that = this;
            var $node = this.selNode.node;
            if (IsNullOrEmpty(that.selNode.node)) {
                ShowMsgBox.call(this, '请选中节点');
                return;
            };

            this.$post(this.$store.getters.sysUrl + '/dwdept/del', {
                rowguid: JSON.parse($node.data('data')).rowguid
            }, function(data){
                // that.oc.removeNodes($node)
                ShowMsg.call(this, '节点删除成功', 'success');
                that.queryData();
                that.selNode = { value: null, node: null };
                that.editShowController = false;
            });

        },
        init: function () {
            var that = this;
            var datascource = Array.isArray(this.dataBase) ? this.dataTree : this.dataBase;

            if (this.oc == null) {
                var dom = that.$refs["chart-container"];
                var randomID = 'chart-container-' + Math.floor(Math.random()*10000);

                dom.setAttribute('id', randomID);

                this.oc = $('#'+randomID).orgchart({
                    'data': datascource || [],
                    'nodeTitle': 'deptname',
                    // 'draggable': true,
                    'zoom': true,
                    'pan': true,
                    'toggleSiblingsResp': false,
                    'createNode': function ($node, data) {
                        $node.data('data', JSON.stringify(data));

                        function calcLiStr(name, icon){
                            name = name || '(空)';
                            return '<li>\
                                <i class="iconfont" slot="suffix">' + icon + '</i>' + name + '\
                            </li>'
                        }

                        var detail = '<ul style="border: 1px solid #9FB1C0; background:white; font-size:10px;">'+
                            calcLiStr(data.fzrusername, '&#xe769;') + calcLiStr(data.fgusername, '&#xe609;') +
                        '</ul>';
                        $node.append(detail);
                    }
                });

                this.oc.$chartContainer.on('click', '.node', function () {
                    var $this = $(this);
                    var nodeObj = JSON.parse($this.data('data'));

                    var formKeys = Object.keys(that.form);
                    formKeys.forEach(function(item){
                        that.form[item] = nodeObj[item];
                    })

                    that.selNode.value = nodeObj.deptname;
                    that.selNode.node = $this;
                    that.editShowController = true;
                });

                this.oc.$chartContainer.on('click', '.orgchart', function (event) {
                    if (!$(event.target).closest('.node').length) {
                        that.selNode={value:null, node:null};
                        that.formClear()
                        that.editShowController = false;
                    }
                });
            } else {
                this.oc.init({
                    'data': datascource
                })
            }
        },
        queryData: function(){
            var that = this;
            that.loadingController = true;
            this.$get(this.$store.getters.sysUrl+this.listpath, function(data){
                that.dataBase = data;
            });
            that.loadingController = false;
        }
    },
    mounted: function () {
        this.queryData();
    }
}
